














































import Vue from "vue";
export default Vue.extend({
	name: "PasswordChange",
	data() {
		return {
			email: "",
			code: "",
			password: "",
			checkPassword: ""
		};
	},
	created() {
		this.email = (this.$route.query.email as string) || "";
		this.code = (this.$route.query.token as string) || "";
	},
	methods: {
		sendEmail() {
			this.$store.commit("pushLoading", {
				name: "SEND_CHANGEPASSWORD_EMAIL",
				message: "이메일 보내는 중"
			});
			this.$store
				.dispatch("SEND_CHANGEPASSWORD_EMAIL", { email: this.email })
				.then(data => {
					this.$store.commit("clearLoading", "SEND_CHANGEPASSWORD_EMAIL");
					this.$store.commit("showNotice", `${this.email} 메일함을 확인해주세요.`);
					this.$router.push("/");
				})
				.catch(err => console.log(err));
		},
		changePassword() {
			if (this.isEmailAuth) {
				if (this.password != this.checkPassword) {
				} else {
					this.$store
						.dispatch("CHANGE_PASSWORD", {
							email: this.email,
							code: this.code,
							newPassword: this.password
						})
						.then(data => {
							this.$store.commit("showNotice", "비밀번호가 재설정되었습니다.");
							this.$router.replace({ name: "login" });
						});
				}
			}
		}
	},
	computed: {
		isEmailAuth() {
			return this.$route.query.token && this.$route.query.email;
		}
	}
});
